/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const judgeMobile = () => {
  const u = navigator.userAgent;
  return {
    //移动终端浏览器版本信息
    trident: u.indexOf("Trident") > -1, //IE内核
    presto: u.indexOf("Presto") > -1, //opera内核
    webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
    gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
    iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
    iPad: u.indexOf("iPad") > -1, //是否iPad
    webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
  };
};
// 是否竖屏
export const getOrient = () => {
  if (
    window.orientation == 0 ||
    window.orientation == 180 ||
    (window.innerWidth == 1240 && window.innerHeight == 1100) ||
    (window.innerWidth == 1100 && window.innerHeight == 1240)
  ) {
    return true;
  } else if (window.orientation == 90 || window.orientation == -90) {
    return false;
  }
};
export const mobileCheck = (n) => {
  return /^[1][0-9][0-9]{9}$/.test(n);
};
export const getFirstFrameByVideo = (list, index, video) => {
  return new Promise((resolve) => {
    let canvasElem = document.createElement("canvas");
    let videoElem = document.createElement("video");
    videoElem.autoplay = true;
    videoElem.muted = true;
    videoElem.preload = true;
    videoElem.src = video;
    videoElem.addEventListener(
      "loadeddata",
      () => {
        let { videoWidth, videoHeight } = videoElem;
        canvasElem.width = videoWidth;
        canvasElem.height = videoHeight;
        canvasElem
          .getContext("2d")
          .drawImage(videoElem, 0, 0, videoWidth, videoHeight);
        list[index] = {
          video: video,
          poster: canvasElem.toDataURL("image/jpeg"),
        };
        resolve();
        videoElem.src = "";
      },
      false
    );
  });
};
export const getFirstFramesByVideos = (list, videos) => {
  let videosPromises = videos.map((i, k) => getFirstFrameByVideo(list, k, i));
  // console.log(videosPromises, "videosPromises");
  videosPromises.reduce((memo, curr) => {
    return memo.then(curr);
  }, Promise.resolve());
  return list;
};
